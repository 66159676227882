<template>
  <!-- 优惠券提示语 -->
  <div
    v-if="cartCouponsInfo"
    v-cloak
    class="cart-coupon section"
  >
    <div class="coupon-msg">
      <div
        v-enterkey
        class="promotion-line ounpon-text"
        role="button"
        tabindex="0"
        @click="showCoupon = !showCoupon"
      >
        <i
          class="i-left sui_icon_activity_24px"
          :class="[iconPrefix]"
        ></i>
        <div class="tip-container">
          <span v-html="couponTip"></span>
        </div>
        <i :class="['i-right', iconPrefix, 'sui_icon_more_up_16px', 'cart-icon-more', {active: showCoupon} ]"></i>
      </div>
      <div
        v-if="showCoupon"
        class="tip-bottom-right coupon-content"
      >
        <div
          v-if="+totalPrice >= +cartCouponsInfo.min_order"
          class="coupon-code"
        >
          {{ language.SHEIN_KEY_PWA_16426 }}: <span class="couponCode">{{ cartCouponsInfo.coupon }}</span>
        </div>
        <!-- 展示现金或者折扣百分比的 -->
        <div
          v-if="cartCouponsInfo.is_member_free_ship_coupon == 0 && cartCouponsInfo.is_free_ship_coupon == 0"
          class="cash-or-discount"
        >
          <!-- 为1是现金,2,5是折扣 -->
          <div
            v-if="cartCouponsInfo.coupon_type_id == 1"
            class="coupon-cash"
          >
            {{ template(cartCouponsInfo.range_list[0].price_symbol, cartCouponsInfo.range_list[0].min_order_symbol, language.SHEIN_KEY_PWA_16811) }}
          </div>
          <div
            v-else-if="cartCouponsInfo.coupon_type_id == 2 || cartCouponsInfo.coupon_type_id == 5"
            class="coupon-discount"
          >
            {{ template(cartCouponsInfo.range_list[0].price_symbol, cartCouponsInfo.range_list[0].min_order_symbol,language.SHEIN_KEY_PWA_16812) }}
          </div>
        </div>
        <!-- 展示免邮的 -->
        <div
          v-else
          class="free-delivery"
        >
          {{ template(cartCouponsInfo.range_list[0].min_order_symbol, language.SHEIN_KEY_PWA_16813) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
const { currency } = gbCommonInfo
import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

export default {
  name: 'CartCoupon',
  props: {
    isSsr: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      showCoupon: false // 是否显示详细的coupon信息
    }
  },
  computed: {
    ...mapState('cart', ['cartInfo', 'language', 'cartCouponsInfo']),
    ...mapGetters('cart', ['iconPrefix']),
    totalPrice () {
      return this.cartInfo && this.cartInfo.totalPrice ? this.cartInfo.totalPrice.amount : 0
    },
    couponTip () {
      let result = this.language.SHEIN_KEY_PWA_16810
      if (+this.totalPrice < +this.cartCouponsInfo.min_order) {
        if (this.cartCouponsInfo.currencyInfo.symbol_left) {
          result = template('<strong>' + this.cartCouponsInfo.currencyInfo.symbol_left + (this.cartCouponsInfo.min_order - this.totalPrice).toFixed(2) + '</strong>', this.language.SHEIN_KEY_PWA_16809)
        } else if (this.cartCouponsInfo.currencyInfo.symbol_right) {
          result = template('<strong>' + this.cartCouponsInfo.currencyInfo.symbol_left + (this.cartCouponsInfo.min_order - this.totalPrice).toFixed(2) + '</strong>', this.language.SHEIN_KEY_PWA_16809)
        }
      }
      return result
    }
  },
  watch: {
    totalPrice (n) {
      this.getCartCouponData(n)
    }
  },
  mounted () {
    !this.isSsr && this.getCartCouponData(this.totalPrice)
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    // 获取购物车优惠券
    getCartCouponData (totalPrice) {
      schttp({
        url: '/api/cart/getCartCoupons/get',
        params: {
          price: totalPrice,
          currency: currency
        }
      }).then( res => {
        this.updateState({ key: 'cartCouponsInfo', value: res.info })
      })
    },
    template: template
  }
}
</script>
<style lang="less">
.cart-coupon {
  background: #fff;
  .coupon-content {
    color: #999;
    .padding-l(1.1rem);
    .padding-r(.32rem);
    padding-bottom: 24/75rem;
    .couponCode {
      font-weight: bold;
    }
    .cash-or-discount {
      font-size: 12px;
      color: #333;
      font-family: Microsoft Yahei!important; /* stylelint-disable-line declaration-no-important */
    }
    .coupon-code{
      color: @sui_color_highlight;
    }
  }
}
.promotion-line{
  position: relative;
  background: #fff;
  .tip-container{
    padding: .32rem 1.1rem;
    strong{
      font-weight: normal;
      color: @sui_color_highlight;
    }
  }
  .i-left{
    position: absolute;
    .left(.32rem);
    .font-dpr(36px);
    top: 50%;
    transform: translateY(-50%);
  }
}
.i-right{
  position: absolute;
  .right(.4rem);
  .font-dpr(36px);
  top: 50%;
  transform: translateY(-50%);
  &.cart-icon-more{
    .right(.25rem);
    transition: all .5s;
    color: @sui_color_gray_light1;
    &.active{
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
</style>
