<template>
  <!-- 购物车头部 -->
  <div
    v-show="!batchActive"
    class="cart-header-wrapper"
    :class="{ 'is-ssr': isSSR }"
  >
    <!-- 购物车优惠券  -->
    <cart-coupon
      v-if="IS_RW"
      :is-ssr="isSSR"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import CartCoupon from '../CartCoupon.vue'

export default {
  name: 'CartHeaderContent',
  components: {
    CartCoupon,
  },
  props: {
    isSSR: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('cart', ['locals', 'batchActive']),
    IS_RW () {
      return this.locals.IS_RW
    },
  }
}
</script>
